<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right d-flex">
            <div class="me-2 d-flex">
              <label class="col-form-label me-2">Budget Year</label>
              <div style="width: 150px">
                <v-select label="name" v-model="year" :options="years" :clearable="false" :reduce="(name) => name.id"
                  @option:selected="getPlansByYear($event)">
                </v-select>
              </div>
            </div>
            <label class="col-form-label me-2">Month</label>
            <div style="width: 165px">
              <v-select label="name" v-model="month" :options="months" :clearable="false" :reduce="(name) => name.id"
                :selectable="(options) => isSelectableMonth(options)"
                @option:selected="getPlansByMonth">
              </v-select>
            </div>
            <label class="col-form-label me-2 ms-2">Process</label>
            <div style="width: 150px">
              <v-select v-model="training_plan_status" label="name" :options="attendance_process"
                :selectable="(options) => isSelectableProcess(options)"
                @option:selected="getStatus($event, this.yearId, this.month)" :clearable="false">
              </v-select>
            </div>
            <div @click="refreshData" class="icon-css">
              <i style="color: #4a81d4" class="fe-rotate-cw"></i>
            </div>
          </div>
          <h4 class="page-title">Training Attendance</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive" v-if="!loading">
              <table class="table nowrap w-100 mb-3 table-hover" id="attendance-datatable" v-if="!loading"
                style="margin-top: 10px !important">
                <!-- style="display: none" -->
                <thead v-if="!loading">
                  <tr>
                    <th>Employee Type</th>
                    <th class="hideColumn">Budget Year</th>
                    <th class="hideColumn">Month</th>
                    <th>Module Group</th>
                    <th>Module</th>
                    <th>Batch</th>
                    <th>Trainer</th>
                    <th>status</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <template v-for="(item, index) in filterAttendanceList" :key="index">
                    <!-- class="table-inlineData" -->
                    <!-- <tr v-if="
                      filterData == false
                        ? true
                        : item.attandence_status == process_data
                    "> -->
                    <tr>
                      <!-- table-leftBorder -->
                      <td class="table-rowCSS">
                        {{ item.employee_type_name }}
                      </td>
                      <td class="hideColumn">
                        {{ item.budget_year }}
                      </td>
                      <td class="hideColumn">
                        {{ item.month }}
                      </td>
                      <td class="table-rowCSS custom-width">
                        {{ item.module_group_name }}
                      </td>
                      <td class="table-rowCSS custom-width">
                        {{ item.module_name }}
                      </td>
                      <td class="table-rowCSS text-primary">
                        {{ item.batch == 0 ? "B-0" : item.batch }}
                      </td>
                      <td class="table-rowCSS custom-width">
                        <span v-for="(trainer, index) in item.trainers" :key="index">{{ trainer.name }}
                          <span v-if="index + 1 < item.trainers.length">, </span>
                        </span>
                      </td>
                      <!-- table-rightBorder -->
                      <td class="table-rowCSS">
                        <router-link :to="{
                          name: 'attendance-confirm-edit',
                          params: { id: item.id },
                        }">
                          <button v-if="item.attandence_status == 0" type="button"
                            class="btn btn-warning btn-sm badge-cursor">Unprocessed</button>
                          <button v-if="item.attandence_status == 1" type="button"
                            class="btn btn-info btn-sm badge-cursor">Onprogess</button>
                          <button v-if="item.attandence_status == 2" type="button"
                            class="btn btn-success btn-sm badge-cursor">Confirmed</button>
                        </router-link>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.table.dataTable.nowrap td {
  white-space: inherit !important;
}

.custom-width {
  max-width: 70px;
}
</style>

<script>
//Datatable TrainingAttendance
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import $ from "jquery";
import axios from "axios";
import moment from "moment";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      loading: false,
      attendanceList: [],
      employees: this.$store.getters["odoo/getAllEmployees"],
      filterLists: [],
      filterAttendanceList: [],
      training_plan_status: "",
      attendance_process: ["Unprocessed", "Onprogess", "Confirmed"],
      current_user_id: this.$store.state.auth.user.employee_id,
      month: "",
      months: [],
      year: "",
      years: [],
      yearId: "",
      process_data: "",
      // filterData: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      // defineTrainingHead: process.env.VUE_APP_DEFINE_TRAINING_HEAD,
    };
  },
  methods: {
    isSelectableMonth(options) {
      if(!this.month) return true;
      return this.month != options.id;
    },
    isSelectableProcess(options) {
      if(!this.training_plan_status) return true;
      return this.training_plan_status!= options;
    },
    async getBudgetYear() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/yearly-training-plans`)
        .then((response) => {
          this.years = response.data.data;
          this.loading = false;
        });
    },
    // async getAllMonths() {
    //   axios.get(`${this.baseUrl}admin/v1/get-months`).then((response) => {
    //     let obj = response.data.data;
    //     this.months = Object.keys(obj).map((key) => {
    //       return { id: key, name: obj[key] };
    //     });
    //   });
    // },

    async getPlansByYear(value) {
      this.yearId = value.id;
      this.attendanceList = "";
      this.filterLists = [];
      this.filterAttendanceList = [];
      this.month = "";
      this.training_plan_status = "";
      // this.filterData = false;
      await this.getAllMonths(this.yearId);
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/training-attendances/monthly-plans?budget_year_id=${this.yearId}`
        )
        .then((response) => {
          this.attendanceList = response.data.data;

          // this.attendanceList.map((item) => {
          //   item.trainers.map((data) => {
          //     if (data.employee_id == this.current_user_id) {
          //       this.filterLists.push(item);
          //     }
          //   });
          // });

          // this.filterAttendanceList = this.filterLists.map((item) => {
          //   var trainerList = item.trainers.map((data) => {
          //     let employee = this.checklist(data.employee_id);
          //     let obj = {
          //       id: employee.emp_id,
          //       name: employee.name,
          //     };
          //     return obj;
          //   });

          //   let attendance = {
          //     id: item.id,
          //     employee_type_name: item.employee_type_name,
          //     module_group_name: item.module_group_name,
          //     module_name: item.module_name,
          //     trainers: trainerList,
          //     batch: item.batch,
          //     attandence_status: item.attandence_status,
          //   };
          //   return attendance;
          // });

          if (
            // this.$store.state.auth.user.employee_id == this.defineTrainingHead
            this.$store.state.auth.user.roles[0] == "Training Head" ||
            this.$store.state.auth.user.roles[0] == "Admin" ||
            this.$store.state.auth.user.roles[0] == "Super Admin"
          ) {
            this.filterAttendanceList = this.attendanceList.map((item) => {
              var trainerList = item.trainers.map((data) => {
                let employee = this.checklist(data.employee_id);
                let obj = {
                  id: employee.emp_id,
                  name: employee.name,
                };
                return obj;
              });

              let attendance = {
                id: item.id,
                employee_type_name: item.employee_type_name,
                budget_year: item.budget_year,
                month: item.month,
                module_group_name: item.module_group_name,
                module_name: item.module_name,
                trainers: trainerList,
                batch: item.batch,
                attandence_status: item.attandence_status,
              };
              return attendance;
            });
          } else {
            this.attendanceList.map((item) => {
              item.trainers.map((data) => {
                if (data.employee_id == this.current_user_id) {
                  this.filterLists.push(item);
                }
              });
            });

            this.filterAttendanceList = this.filterLists.map((item) => {
              var trainerList = item.trainers.map((data) => {
                let employee = this.checklist(data.employee_id);
                let obj = {
                  id: employee.emp_id,
                  name: employee.name,
                };
                return obj;
              });

              let attendance = {
                id: item.id,
                employee_type_name: item.employee_type_name,
                budget_year: item.budget_year,
                month: item.month,
                module_group_name: item.module_group_name,
                module_name: item.module_name,
                trainers: trainerList,
                batch: item.batch,
                attandence_status: item.attandence_status,
              };
              return attendance;
            });
          }

          this.loading = false;
          this.$Progress.finish();
        });
      $("#attendance-datatable").DataTable({
        responsive: false,
        destroy: true,
      });
    },

    async getAllMonths(year_id) {
      axios
        .get(`${this.baseUrl}admin/v1/get-months/${year_id}`)
        .then((response) => {
          let obj = response.data.data;
          this.months = Object.keys(obj).map((key) => {
            return { id: key, name: obj[key] };
          });
        });
    },

    async getPlansByMonth() {
      if (this.yearId) {
        this.attendanceList = "";
        this.filterLists = [];
        this.filterAttendanceList = [];
        this.training_plan_status = "";
        // this.filterData = false;
        this.loading = true;
        this.$Progress.start();
        await axios
          .get(
            `${this.baseUrl}admin/v1/training-attendances/monthly-plans?budget_year_id=${this.yearId}&month=${this.month}`
          )
          .then((response) => {
            this.attendanceList = response.data.data;

            if (
              // this.$store.state.auth.user.employee_id == this.defineTrainingHead
              this.$store.state.auth.user.roles[0] == "Training Head" ||
              this.$store.state.auth.user.roles[0] == "Admin" ||
            this.$store.state.auth.user.roles[0] == "Super Admin"
            ) {
              this.filterAttendanceList = this.attendanceList.map((item) => {
                var trainerList = item.trainers.map((data) => {
                  let employee = this.checklist(data.employee_id);
                  let obj = {
                    id: employee.emp_id,
                    name: employee.name,
                  };
                  return obj;
                });

                let attendance = {
                  id: item.id,
                  employee_type_name: item.employee_type_name,
                  budget_year: item.budget_year,
                  month: item.month,
                  module_group_name: item.module_group_name,
                  module_name: item.module_name,
                  trainers: trainerList,
                  batch: item.batch,
                  attandence_status: item.attandence_status,
                };
                return attendance;
              });
            } else {
              this.attendanceList.map((item) => {
                item.trainers.map((data) => {
                  if (data.employee_id == this.current_user_id) {
                    this.filterLists.push(item);
                  }
                });
              });

              this.filterAttendanceList = this.filterLists.map((item) => {
                var trainerList = item.trainers.map((data) => {
                  let employee = this.checklist(data.employee_id);
                  let obj = {
                    id: employee.emp_id,
                    name: employee.name,
                  };
                  return obj;
                });

                let attendance = {
                  id: item.id,
                  employee_type_name: item.employee_type_name,
                  budget_year: item.budget_year,
                  month: item.month,
                  module_group_name: item.module_group_name,
                  module_name: item.module_name,
                  trainers: trainerList,
                  batch: item.batch,
                  attandence_status: item.attandence_status,
                };
                return attendance;
              });
            }

            this.loading = false;
            this.$Progress.finish();
          });
        $("#attendance-datatable").DataTable({
          responsive: false,
          destroy: true,
        });
      } else {
        this.month = "";
        this.toast.error("Please Choose Budget Year First!");
      }
    },

    async getEmployees() {
      // this.loading = true;
      // this.$Progress.start();
      // await axios
      //   .get(`${this.baseUrlHRIS}api/employees`, {
      //     headers: {
      //       "content-type": "text/plain",
      //     },
      //   })
      //   .then((response) => {
      //     this.employees = response.data.data;

      //     this.loading = false;
      //     this.$Progress.finish();
      //   })
      //   .catch(() => {
      //     this.toast.error("Not Found Employees!");
      //   });
      if (this.employees.length == 0) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
    checklist(empId) {
      let resultArray;
      this.employees.forEach(function (emp) {
        if (emp.emp_id == empId) {
          resultArray = emp;
        }
      });
      return resultArray;
    },
    async getAllTrainingAttendance() {
      await this.getEmployees();
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/training-attendances/monthly-plans`)
        .then((response) => {
          this.attendanceList = response.data.data;

          if (
            // this.$store.state.auth.user.employee_id == this.defineTrainingHead
            this.$store.state.auth.user.roles[0] == "Training Head" ||
            this.$store.state.auth.user.roles[0] == "Admin" ||
            this.$store.state.auth.user.roles[0] == "Super Admin"
          ) {
            this.filterAttendanceList = this.attendanceList.map((item) => {
              var trainerList = item.trainers.map((data) => {
                let employee = this.checklist(data.employee_id);
                let obj = {
                  id: employee.emp_id,
                  name: employee.name,
                };
                return obj;
              });

              let attendance = {
                id: item.id,
                employee_type_name: item.employee_type_name,
                budget_year: item.budget_year,
                month: item.month,
                module_group_name: item.module_group_name,
                module_name: item.module_name,
                trainers: trainerList,
                batch: item.batch,
                attandence_status: item.attandence_status,
              };
              return attendance;
            });
          } else {
            this.attendanceList.map((item) => {
              item.trainers.map((data) => {
                if (data.employee_id == this.current_user_id) {
                  this.filterLists.push(item);
                }
              });
            });

            this.filterAttendanceList = this.filterLists.map((item) => {
              var trainerList = item.trainers.map((data) => {
                let employee = this.checklist(data.employee_id);
                let obj = {
                  id: employee.emp_id,
                  name: employee.name,
                };
                return obj;
              });

              let attendance = {
                id: item.id,
                employee_type_name: item.employee_type_name,
                budget_year: item.budget_year,
                month: item.month,
                module_group_name: item.module_group_name,
                module_name: item.module_name,
                trainers: trainerList,
                batch: item.batch,
                attandence_status: item.attandence_status,
              };
              return attendance;
            });
          }

          this.loading = false;
          this.$Progress.finish();
        });
      $("#attendance-datatable").DataTable({
        responsive: false,
        destroy: true,
      });
      this.clearFilter();
    },

    // getStatus(value) {
    //   if (value == "Unprocessed") {
    //     this.process_data = 0;
    //   } else if (value == "Onprogess") {
    //     this.process_data = 1;
    //   } else if (value == "Confirmed") {
    //     this.process_data = 2;
    //   }
    //   this.filterData = true;
    // },

    getStatus(value, year_id, month) {
      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        console.log(settings, dataIndex);
        let yearFilter = year_id;
        let yearStage = data[1];
        let monthFilter = month;
        let monthStage = data[2]; // stage from datatable
        let statusFilter = value;
        let statusStage = data[7];
        if (yearFilter == yearStage && monthFilter == monthStage && statusFilter == statusStage) {
          return true;
        }
        return false;
      });
      $("#attendance-datatable").DataTable().draw();
    },

    refreshData() {
      this.year = "";
      this.month = "";
      this.yearId = "";
      this.training_plan_status = "";
      this.attendanceList = "";
      this.filterLists = [];
      this.filterAttendanceList = [];
      // this.filterData = false;
      this.getBudgetYear();
      // this.getAllMonths();
      this.getAllTrainingAttendance();
      this.current_date = moment().format("YYYY-MM-DD");
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#attendance-datatable").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getBudgetYear();
    // this.getAllMonths();
    this.getAllTrainingAttendance();
    this.current_date = moment().format("YYYY-MM-DD");
  },
  components: {
    vSelect,
  },
};
</script>

<style scoped>
.hideColumn {
  display: none !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
